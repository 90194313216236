export const ENV = 'stage';
// Manual deploy 03

export const ENDPOINTS = {
	API: {
		prod: 'https://api.vitawallet.io/api',
		stage: 'https://api.stage.vitawallet.io/api',
		qa: 'https://api.qa.vitawallet.io/api',
		dev: 'http://192.168.1.115:3000/api',
		local: 'http://localhost:3000/api'
	},
	TRACK: {
		prod: 'https://tracking.vitawallet.io/seguimiento-de-operaciones',
		stage: 'https://tracking.stage.vitawallet.io/seguimiento-de-operaciones',
		qa: 'https://tracking.qa.vitawallet.io/seguimiento-de-operaciones',
		dev: '',
		local: 'http://localhost:3005/seguimiento-de-operaciones'
	},
	PAGE: {
		prod: 'https://app.vitawallet.io/',
		stage: 'https://stage.vitawallet.io/',
		qa: 'https://qa.vitawallet.io/',
		dev: `http://192.168.1.115/`,
		local: `http://localhost/`,
	},
	ADMIN: {
		USER_TRX_LIMITS: {
			GET: {
				method: 'get',
				url: 'admin/user_trx_limits',
			},
			UPDATE: {
				method: 'put',
				url: 'admin/user_trx_limits/:id',
			},
			CREATE: {
				method: 'post',
				url: 'admin/user_trx_limits',
			},
		},
		GENERAL_LIMITS: {
			CREATE: {
				method: 'post',
				url: 'admin/general_limits',
			},
			GET: {
				method: 'get',
				url: 'admin/general_limits',
			},
			DELETE: {
				method: 'delete',
				url: 'admin/general_limits/:id',
			},
			GET_COUNTRIES: {
				method: 'get',
				url: 'admin/general_limits/get_countries',
			},
			UPDATE: {
				method: 'put',
				url: 'admin/general_limits/:id',
			}
		},
		PROVIDERS: {
			GET: {
				method: 'get',
				url: 'admin/providers',
			},
			GET_PROVIDER_BALANCE: {
				method: 'get',
				url: 'admin/providers/get_provider_balance?provider=:provider',
			},
		},
		COUNTRY_CURRENCY: {
			GET: (id) => {
				return {
					method: "get",
					url: `admin/countries/${id}/get_currencies`
				}
			},
			DISABLED_COUNTRIES_CURRENCIES: (id) => {
				return {
					method: "get",
					url: `admin/countries/${id}/get_disabled_countries_currencies`
				}
			},
			ENABLE_CURRENCY: (id) => {
				return {
					method: "post",
					url: `admin/countries/${id}/enable_currency`
				}
			},
			MANAGE_DEFAULT_CURRENCY: (id) => {
				return {
					method: "put",
					url: `admin/countries/${id}/manage_default_currency`
				}
			},
		},
		AUTH: {
			SIGN_IN: {
				method: 'post',
				url: 'auth/sign_in'
			},
			SIGN_OUT: {
				method: 'delete',
				url: 'auth/sign_out'
			},
			CONFIRM_SECOND_FACTOR: {
				method: 'post',
				url: 'users/confirm_second_factor'
			},
			AUTH_CONFIRM_SECOND_FACTOR: {
				method: 'post',
				url: '/auth/second_factors',
			},
			AUTH_RESEND_CODE_DOUBLE_AUTHENTICATION: {
				method: 'post',
				url: '/auth/second_factors/resend',
			},
		},
		BANKS: {
			LIST: {
				method: 'get',
				url: 'banks'
			},
			COUNTRY_CODE_BANK: {
				method: 'post',
				url: 'banks/country_code_banks'
			}
		},
		VITA_PRICES: {
			DELETE_VITA_PRICE: {
				method: 'delete',
				url: 'admin/vita_prices/:id',
			},
			GET_CURRENCIES: {
				method: 'get',
				url: 'admin/vita_prices/get_configuration',
			},
		},
		PRICES: {
			BTC: {
				method: 'get',
				url: 'admin/show_prices_btc'
			},
			DELETE_PRICE_CONFIGURATION: {
				method: 'delete',
				url: 'admin/prices_configuration/:id'
			},
			GET_STATUS_OF_PRICE_PROVIDERS: {
				method: 'get',
				url: 'admin/prices_providers/get_status',
			},
		},
		COUNTRIES: {
			LIST: {
				method: 'get',
				url: 'countries?q[s]=name+ASC',
			},
			LIST_ADMIN: {
				method: 'get',
				url: 'admin/countries?q[s]=name+ASC',
			},
			STATES: (id) => {
				return {
					method: 'get',
					url: `countries/${id}/states`,
				}
			},
			CITIES: (id) => {
				return {
					method: 'get',
					url: `countries/${id}/cities`,
				}
			},
			ACTIVATE_CASHOUT: (id) => {
				return {
					method: 'put',
					url: `admin/countries/${id}/activate_cashout`,
				}
			},
			DEACTIVATE_CASHOUT: (id) => {
				return {
					method: 'put',
					url: `admin/countries/${id}/deactivate_cashout`,
				}
			},
			GET_LIMITS: (id) => {
				return {
					method: 'get',
					url: `admin/transactions_limits?q[country_id_eq]=${id}`,
				}
			},
			SET_LIMITS: (id) => {
				return {
					method: 'put',
					url: `admin/transactions_limits/${id}`,
				}
			},
			GET_AUTOMATIC_PAYMENT_BENEFICIARY_MAX_MONTHLY_LIMIT_AMOUNT: (countryId) => {
				return {
					method: 'get',
					url: `admin/countries/${countryId}/automatic_payment_beneficiary_max_monthly_limit_amount`
				}
			},
			SET_AUTOMATIC_PAYMENT_BENEFICIARY_MAX_MONTHLY_LIMIT_AMOUNT: (countryId) => {
				return {
					method: 'put',
					url: `admin/countries/${countryId}/automatic_payment_beneficiary_max_monthly_limit_amount`
				}
			}
		},
		USERS: {
			ACCEPTED_VERIFICATION: (id) => {
				return {
					method: 'put',
					url: `admin/users/${id}/accept`
				}
			},
			REQUEST_CRIMINAL_VERIFICATION: (id, provider) => {
				return {
					method: 'post',
					url: `admin/users/${id}/criminal_verification/?provider=${provider}`
				}
			},
			UPDATE_CRIMINAL_VERIFICATION_ITEM_IGNORE_STATUS: (user_id, item_id) => {
				return {
					method: 'put',
					url: `admin/users/${user_id}/criminal_verification_items/${item_id}`
				}
			},
			UNBANNED_USER: (id) => {
				return {
					method: 'put',
					url: `admin/users/${id}/set_unbanned`
				}
			},
			BANNED_USER: (id) => {
				return {
					method: 'put',
					url: `admin/users/${id}/set_banned`
				}
			},
			REJECTED_VERIFICATION: (id) => {
				return {
					method: 'put',
					url: `admin/users/${id}/reject`
				}
			},
			RESET_VERIFICATION: (id) => {
				return {
					method: 'put',
					url: `admin/users/${id}/reset`
				}
			},
			REQUEST_BIOMETRIC_VERIFICATION: (id) => {
				return {
					method: 'put',
					url: `admin/users/${id}/biometric_request`
				}
			},
			ACCEPT_BIOMETRIC_VERIFICATION: (id) => {
				return {
					method: 'put',
					url: `admin/users/${id}/biometric_accept`
				}
			},
			REJECT_BIOMETRIC_VERIFICATION: (id) => {
				return {
					method: 'put',
					url: `admin/users/${id}/biometric_reject`
				}
			},
			RESET_BIOMETRIC_VERIFICATION: (id) => {
				return {
					method: 'put',
					url: `admin/users/${id}/biometric_reset`
				}
			},
			RELOAD_BIOMETRIC_VERIFICATION: (id) => {
				return {
					method: 'put',
					url: `admin/users/${id}/biometric_reload`
				}
			},
			RESET_SESSION: (id) => {
				return {
					method: 'post',
					url: `admin/users/${id}/reset_session`
				}
			},
			USERS_LIST: (queryFilter) => {
				return {
					method: 'get',
					url: `admin/users${queryFilter}`
				}
			},
			PROFILE: {
				method: 'get',
				url: 'profile',
			},
			BALANCE: {
				method: 'get',
				url: 'admin/balance'
			},
			PENDING_TRANSACTIONS_BALANCE: {
				method: 'get',
				url: 'admin/pending_transactions_balance'
			},
			GENERAL_BALANCE: {
				method: 'get',
				url: 'admin/general_balance'
			},
			BALANCE_PER_COUNTRY: (iso_code) => ({
				method: 'get',
				url: `admin/balance_per_country/${iso_code}`
			}),
			GET_USER_LIMIT: {
				method: 'get',
				url: 'admin/user_transactions_limits?q[user_id_eq]=:user_id',
			},
			ADD_USER_LIMIT: {
				method: 'post',
				url: 'admin/user_transactions_limits',
			},
			DELETE_USER_LIMIT: {
				method: 'delete',
				url: 'admin/user_transactions_limits/:id',
			},
			USER_STATUS_LOGS: (id) => {
				return {
					method: 'get',
					url: `admin/users/${id}/verification_status_record`
				}
			},
			REQUEST_UPDATE_PROFILE: (id) => {
				return {
					method: 'post',
					url: `admin/users/${id}/request_update_profile`
				}
			},
			CREATE_SEND_USER_ROUTE: {
				method: 'post',
				url: `admin/users/create_send_user_route`
			},
			GET_SEND_USER_ROUTES: (id) => {
				return {
					method: 'get',
					url: `admin/users/${id}/get_current_send_user_routes`
				}
			},
			GET_AVAILABLE_SEND_ROUTES: (id) => {
				return {
					method: 'get',
					url: `admin/users/${id}/get_available_send_routes`
				}
			},
			SAVE_EDIT_USER_ROUTE: {
				method: 'post',
				url: `admin/users/save_edit_user_route`
			},
			DELETE_USER_ROUTE: {
				method: 'post',
				url: `admin/users/delete_user_route`
			},
			PREFERENTIAL_AUTOMATIC_PRICES: {
				method: 'post',
				url: `admin/users/preferential_automatic_prices`
			},
			USER_QUOTATION_PRICES: (id) => {
				return {
					method: 'GET',
					url: `admin/users/${id}/get_fiat_multi_prices`,
				}
			},
			USER_CRYPTO_QUOTATION_PRICES: (id) => {
				return {
					method: 'GET',
					url: `admin/users/${id}/get_crypto_multi_prices`,
				}
			},
			PENDING_TRANSACTIONS_LIST: {
				method: 'get',
				url: 'admin/transactions/transactions_list_summary'
			},
			OLD_PENDING_TRANSACTIONS_LIST: (query) => ({
				method: 'get',
				url: `admin/transactions/transactions_pending_oldest${query}`
			}),
			VALIDATE_CURRENCY: (email, currency) => ({
				method: 'get',
				url: `admin/users/validate_currency?address=${email}&address_type=${currency}`
			}),
			VALIDATE_EMAIL: (id, email) => ({
				method: 'get',
				url: `admin/users/${id}/validate_email?email=${email}`
			}),
			UPDATE_EMAIL: (id, email) => ({
				method: 'put',
				url: `admin/users/${id}/update_email?email=${email}`
			}),
			UPDATE_CATEGORY: (id, category) => ({
				method: 'put',
				url: `admin/users/${id}/update_category?category=${category}`
			}),
			UPDATE_BUSINESS: (user_id) => ({
				method: 'put',
				url: `admin/users/${user_id}/business`
			}),
			SUPPRESS_USER: {
				method: 'post',
				url: `admin/users/handle_suppress_users`,
			},
			GET_TRX_LIMITS: {
				method: 'get',
				url: 'admin/users/:id/get_trx_limits',
			},
			ASSIGN_STP_CLABE_NUMBER: (user_id) => ({
				method: 'post',
				url: `admin/users/${user_id}/assign_clabe`
			}),
			GET_INFO_PAYMENT_LINK_USER_BUSINESS: (userId) => {
				return {
					method: 'get',
					url: `admin/users/${userId}/business_payment_order_config`
				}
			},
			UPDATE_INFO_PAYMENT_LINK_USER_BUSINESS: (userId) => {
				return {
					method: 'put',
					url: `admin/users/${userId}/business_payment_order_config`
				}
			},
			ASIGN_PROVIDER_PAYMENT_AVAILABLE_USER_BUSINESS: (userId) => {
				return {
					method: 'post',
					url: `admin/users/${userId}/payment_order_providers`
				}
			},
			GET_PROVIDERS_ACTIVES_USER_BUSINESS: (userId) => {
				return {
					method: 'get',
					url: `admin/users/${userId}/payment_order_providers`
				}
			},
			UPDATE_PROVIDER_PAYMENT_ACTIVE_USER_BUSINESS: (userId, providerId) => {
				return {
					method: 'put',
					url: `admin/users/${userId}/payment_order_providers/${providerId}`
				}
			},
			DELETE_PROVIDER_PAYMENT_ACTIVE_USER_BUSINESS: (userId, providerId) => {
				return {
					method: 'delete',
					url: `admin/users/${userId}/payment_order_providers/${providerId}`
				}
			},
			GET_BUSINESS_PAYMENT_ORDERS: (userId, page, count) => {
				return {
					method: 'get',
					url: `admin/users/${userId}/business_payment_orders?count=${count}&page=${page}`
				}
			},
			GET_USER_BALANCES: (id) => {
				return {
					method: "get",
					url: `admin/users/${id}/get_user_balances`
				}
			},
			GET_AVAILABLE_CURRENCIES: (id) => {
				return {
					method: "get",
					url: `admin/users/${id}/get_available_currencies`
				}
			},
			ACTIVATE_CURRENCY_TO_USER: (user_id) => {
				return {
					method: 'post',
					url: `admin/users/${user_id}/activate_currency_to_user`,
				}
			},
			GET_BALANCES_FILE: {
				method: 'get',
				url: `admin/users/get_user_balances_file`,
			},
			GET_BALANCE_ADMIN_EMAIL: {
				method: 'get',
				url: `admin/get_balance_admin_email`,
			},
			GET_INFO_USD_ACCOUNT: (user_id) => {
				return {
					method: 'get',
					url: `admin/users/${user_id}/get_info_usd_account`,
				}
			},
			REJECT_USD_ACCOUNT: (user_id) => {
				return {
					method: 'put',
					url: `admin/users/${user_id}/reject_usd_account`,
				}
			},
			RETRY_USD_ACCOUNT_CREATION: (user_id) => {
				return {
					method: 'put',
					url: `admin/users/${user_id}/retry_usd_account_creation`,
				}
			},
			MANUAL_UPDATE_USD_ACCOUNT: (user_id) => {
				return {
					method: 'put',
					url: `admin/users/${user_id}/manual_update_usd_account`,
				}
			},
		},
		BENEFICIARIES: {
			GET_AMOUNTS: (id, params) => {
				return {
					method: "get",
					url: `admin/beneficiaries/${id}/get_total_amount${params}`
				}
			},
			REQUEST_CRIMINAL_VERIFICATION: (id, provider) => {
				return {
					method: 'post',
					url: `admin/beneficiaries/${id}/criminal_verification?provider=${provider}`
				}
			},
			UPDATE_CRIMINAL_VERIFICATION_ITEM_IGNORE_STATUS: (user_id, item_id) => {
				return {
					method: 'put',
					url: `admin/beneficiaries/${user_id}/criminal_verification_items/${item_id}`
				}
			},
			UPDATE: (id) => {
				return {
					method: 'put',
					url: `admin/beneficiaries/${id}`
				}
			}
		},
		BANK_MOVEMENTS: {
			DELETE: {
				method: 'delete',
				url: `admin/bank_movements`,
			},
			ASSING: {
				method: 'post',
				url: `admin/bank_movements`,
			},
			LAST_UPDATE: {
				method: 'get',
				url: 'admin/bank_movements/account_status'
			},
			UPDATE_DOCUMENT: {
				method: 'post',
				url: `admin/bank_movements/update_document_sender`,
			}
		},
		FINTOC: {
			DELETE: {
				method: 'delete',
				url: `admin/fintoc`,
			},
			ASSING: {
				method: 'post',
				url: `admin/fintoc`,
			},
			LAST_UPDATE: {
				method: 'get',
				url: 'admin/fintocs/account_status'
			}
		},
		REFERRER: {
			GET_LIST: {
				method: 'get',
				url: 'admin/referrers'
			}
		},
		SERVICES: {
			GET_STATUS: (utility_number) => {
				return {
					method: 'put',
					url: `admin/services/${utility_number}/service_status`,
				}
			},
		},
		CURRENCIES: {
			GET_CURRENCIES: {
				method: "get",
				url: 'admin/currencies'
			}
		},
		TRANSACTIONS: {
			ADD_BALANCE_FOR_ADMIN: {
				method: 'post',
				url: 'admin/transactions/add_balance_for_admin',
			},
			CREATE_RULES_COUNTRY: {
				method: 'post',
				url: 'admin/transactions/create_rules_country',
			},
			CREATE_BANK_SECTION: {
				method: 'post',
				url: 'admin/transactions/create_bank_section',
			},
			TRANSFER_RULES: {
				method: 'get',
				url: 'admin/transactions/transfer_rules',
			},
			DELETE_TRANSFER_RULES: {
				method: 'delete',
				url: 'admin/transactions/delete_transfer_rules',
			},
			CREATE_TRANSFER_RULES: {
				method: 'post',
				url: 'admin/transactions/create_transfer_rules',
			},
			BATCH_PAYOUT: {
				method: 'post',
				url: 'admin/transactions/batch_payout',
			},
			RECHARGE_LOTE: {
				method: 'post',
				url: 'admin/transactions/1/automatic_lote',
			},
			BITCOIN_WITHDRAWAL: {
				method: 'post',
				url: 'admin/transactions/create_btc_withdrawal',
			},
			ACCEPTED_WITHDRAWAL: {
				method: 'post',
				url: 'admin/payments/create_payout',
			},
			CHANGE_STATUS: {
				method: 'put',
				url: 'admin/transactions/change_status',
			},
			ACCEPTED_WITHDRAWAL_MANUAL: (id) => {
				return {
					method: 'put',
					url: `admin/transactions/${id}/create_payout`,
				}
			},
			REJECTED_WITHDRAWAL: (id) => {
				return {
					method: 'put',
					url: `admin/transactions/${id}/reject_payout`,
				}
			},
			CANCEL_PAYMENT: {
				method: 'post',
				url: 'admin/payments/cancel_payout',
			},
			ASSIGN_HASH: (id) => {
				return {
					method: 'put',
					url: `admin/transactions/${id}/complete_external_btc_transaction`
				}
			},
			GET_TRANSACTION: (id) => {
				return {
					method: 'get',
					url: `admin/transactions?filter={"id":${id}}`,
				}
			},
			CREATE_DISPERSION_TO_FAILED_TRANSFER: (id) => {
				return {
					method: 'put',
					url: `admin/transactions/${id}/create_dispersion_to_failed`,
				}
			},
			GET_TOTAL_AMOUNT_RECIPIENT: (document_number, iso_code_country) => {
				return {
					method: 'get',
					url: `admin/transactions/total_amount_recipient?document_number=${document_number}&iso_code_country=${iso_code_country}`,
				}
			},
			GET_COMMENTS: (id, { page, count }) => ({
				method: 'get',
				url: `admin/transactions/${id}/comments?page=${page}&count=${count}&q[s]=created_at+DESC`,
			}),
			POST_COMMENTS: (id) => ({
				method: 'post',
				url: `admin/transactions/${id}/comments`,
			}),
			UPDATE_LOCKED_STATUS: (id) => {
				return {
					method: 'put',
					url: `admin/transactions/${id}/locked_status`
				}
			},
			CREATE_TRANSACTION: {
				method: 'post',
				url: `admin/transactions`
			},
			REMOVE_FROM_TRANSACTION_BATCH: (id) => {
				return {
					method: 'put',
					url: `admin/transactions/${id}/remove_from_transaction_batch`
				}
			},
			GET_CRYPTO_RISK: (id) => {
				return {
					method: 'get',
					url: `admin/transactions/get_crypto_risk?id=${id}`
				}
			}
		},
		PAYMENTS: {
			CREATE_CHARGE: {
				method: 'post',
				url: 'admin/payments/create_payin'
			}
		},
		BUSINESSES: {
			GET_BUSINESS: {
				method: 'get',
				url: 'admin/businesses',
			},
			CREATE_BUSINESS: {
				method: 'post',
				url: 'admin/businesses',
			},
			CANCEL_BUSINESS: {
				method: 'delete',
				url: 'admin/businesses',
			},
			ADD_IP: {
				method: 'post',
				url: 'admin/businesses/add_ip',
			},
			ADD_BALANCE: {
				method: 'post',
				url: 'admin/businesses/add_balance',
			},
			UPDATE_RULES: {
				method: 'put',
				url: 'admin/businesses/update_rules',
			},
			CANCEL_IP: {
				method: 'put',
				url: 'admin/businesses/cancel_ip',
			},
			ASSIGN_IP: {
				method: 'put',
				url: 'admin/businesses/assign_ip',
			},
			UPDATE_REPORT: {
				method: 'put',
				url: 'admin/businesses/transactions_report',
			},
			UPDATE_ENCRYPT_REPORT: {
				method: 'put',
				url: 'admin/businesses/encrypt_transactions_report',
			},
			UPDATE_MONTHLY_REPORT: {
				method: 'put',
				url: 'admin/businesses/monthly_transactions_report',
			},
			UPDATE_ENCRYPT_MONTHLY_REPORT: {
				method: 'put',
				url: 'admin/businesses/encrypt_monthly_transactions_report',
			},
			UPDATE_FINAL_CUSTOMER_SERVICE_STATUS: {
				method: 'put',
				url: `admin/businesses/final_customer_service_status`
			}
		},
		ROLES: {
			GET_ROLES: {
				method: 'get',
				url: 'admin/roles_without_page'
			}
		},
		PERMISSIONS: {
			GET_PERMISSIONS: {
				method: 'get',
				url: 'admin/permissions'
			}
		},
		MESSAGES: {
			MARKETING: {
				CHANGE_ACTIVE_STATUS: (id) => {
					return {
						method: 'put',
						url: `admin/marketing_message/${id}/chance_active_status`
					}
				},
				CHANGE_FIXED_STATUS: (id) => {
					return {
						method: 'put',
						url: `admin/marketing_message/${id}/chance_fixed_status`
					}
				},
				CREATE_MESSAGE: (id) => {
					return {
						method: 'post',
						url: `admin/marketing_message/${id}/create_message`
					}
				},
				EDIT_MESSAGE: (id) => {
					return {
						method: 'post',
						url: `admin/marketing_message/${id}/edit_message`
					}
				},
				DELETE_MESSAGE: (id) => {
					return {
						method: 'delete',
						url: `admin/marketing_message/${id}/delete_message`
					}
				}
			}
		},
		FAILED_TRANSACTION: {
			GET_LIST: (id) => {
				return {
					method: 'get',
					url: `admin/failed_transactions/${id}/get_list`
				}
			}
		},
		FAVORITE_ACCOUNT: {
			GET_LIST: (url) => {
				return {
					method: 'get',
					url: `admin/favorite_accounts?${url}`
				}
			},
			CREATE: {
				method: 'post',
				url: `admin/favorite_accounts`
			},
			UPDATE: (id) => {
				return {
					method: 'put',
					url: `admin/favorite_accounts/${id}`
				}
			},
			DELETE: (id) => {
				return {
					method: 'delete',
					url: `admin/favorite_accounts/${id}/`
				}
			}
		},
		BANK_ACCOUNT: {
			CHANGE_ACTIVITY_ACCESS: (id) => {
				return {
					method: 'put',
					url: `admin/bank_account_accesses/${id}`
				}
			},
			CREATE: {
				method: 'post',
				url: `admin/banks_accounts`
			},
			GET_LIST: (url) => {
				return {
					method: 'get',
					url: `admin/banks_accounts?${url}`
				}
			},
			UPDATE: (id) => {
				return {
					method: 'put',
					url: `admin/banks_accounts/${id}`
				}
			},
			DELETE: (id) => {
				return {
					method: 'delete',
					url: `admin/banks_accounts/${id}/`
				}
			}
		},
		TRANSFER_RULES: {
			GET_RULES: {
				method: 'get',
				url: 'admin/transactions/transfer_rules'
			}
		},
		CARD_TRANSACTION: {
			UPDATE_TRANSFER: (id) => {
				return {
					method: 'put',
					url: `admin/card_transactions/${id}/resend_transfer`
				}
			},
			GET_TRANSACTION: (id) => {
				return {
					method: 'get',
					url: `admin/card_transactions/${id}`
				}
			},
		},
		SEGMENTS: {
			LIST: {
				method: "get",
				url: "admin/segments",
			},
		},
		OBJECTIVES: {
			GET_OBJECTIVES: {
				method: 'get',
				url: 'admin/history/target_types'
			}
		},
		CARD_ACCOUNT: {
			CREATE_CARD_ACCOUNT: (id) => {
				return {
					method: 'post',
					url: `admin/card_accounts/${id}/create_account`
				}
			},
			GET_CARD_ACCOUNT: (id) => {
				return {
					method: 'get',
					url: `admin/card_accounts/${id}`
				}
			},
			UPDATE: (id) => {
				return {
					method: 'put',
					url: `admin/card_accounts/${id}/edit_address`
				}
			},
			GET_BALANCE: (account_id) => {
				return {
					method: 'get',
					url: `admin/card_accounts/${account_id}/balance`
				}
			},
		},
		CARD_REQUEST: {
			CREATE_CARD_REQUEST: (id) => {
				return {
					method: 'post',
					url: `admin/card_requests/${id}/create_request`
				}
			},
			UPDATE_STATUS: {
				method: 'put',
				url: 'admin/card_requests/update_status'
			},
			UPDATE_CURRENCY: {
				method: 'put',
				url: 'admin/card_requests/update_currency'
			},
			GET_OLD_CARD: (id) => {
				return {
					method: 'get',
					url: `admin/card_requests/${id}/old_cards`
				}
			},
			GET_CARD_FROM_POMELO: (id) => {
				return {
					method: 'get',
					url: `admin/card_requests/${id}/update_card_with_pomelo`
				}
			},
		},
		CATEGORY: {
			GET_CATEGORIES: {
				method: 'get',
				url: 'admin/categories',
			},
			DELETE: (id) => {
				return {
					method: 'delete',
					url: `admin/categories/${id}`,
				}
			},
		},
		FORM_TYPES: {
			GET_FORM: {
				method: "get",
				url: "admin/form_types/get_by_type",
			},
			GET_OPTIONS: {
				method: "get",
				url: "admin/form_types/get_options",
			}
		},
		EMAIL_SEGMENTS: {
			TEST_SEGMENT: {
				method: "post",
				url: "admin/check_segment",
			}
		},
		BINANCE: {
			AUTOMATIC_BINANCE_WITHDRAWAL: {
				method: "post",
				url: "admin/binance/automatic_withdrawal"
			},
			COMPLETE_BINANCE_WITHDRAWAL: {
				method: "post",
				url: "admin/binance/complete_withdrawal"
			},
			REJECT_BINANCE_WITHDRWAL: {
				method: "put",
				url: "admin/binance/reject_withdrawal"
			},
			BALANCE: {
				method: "get",
				url: "admin/binance/binance_balance"
			},
			MASTER_ADMIN_BALANCES: {
				method: "get",
				url: "admin/binance/master_admin_balances"
			},
			GET_BINANCE_NETWORKS: (currency) => {
				return {
					method: "get",
					url: `admin/binance_networks/get_networks?currency=${currency}`
				}
			},
			BINANCE_WITHDRAWAL: {
				method: "post",
				url: "admin/binance/binance_withdrawal"
			}
		},
		USDC_WITHDRAWALS: {
			AUTOMATIC_WITHDRAWAL: {
				method: "post",
				url: "admin/usdc_withdrawals/automatic_withdrawal",
			},
			COMPLETE_WITHDRAWAL: {
				method: "post",
				url: "admin/usdc_withdrawals/complete_withdrawal",
			},
			REJECT_WITHDRWAL: {
				method: "put",
				url: "admin/usdc_withdrawals/reject_withdrawal",
			},
			BALANCE: {
				method: "get",
				url: "admin/usdc_withdrawals/circle_balance",
			},
			MASTER_ADMIN_BALANCES: {
				method: "get",
				url: "admin/usdc_withdrawals/master_admin_balances",
			},
			USER_CIRCLE_BALANCES: (id) => {
				return {
					method: "get",
					url: `admin/usdc_withdrawals/${id}/user_circle_balances`,
				};
			},
			GET_CIRCLE_NETWORKS: {
				method: "get",
				url: "admin/circle_networks/get_networks",
			},
			CIRCLE_WITHDRAWAL: {
				method: "post",
				url: "admin/usdc_withdrawals/circle_withdrawal",
			},
		},
		GEOLOCATION: {
			GEOLOCATION_LIST: {
				method: 'get',
				url: 'admin/geolocations'
			},
		},
		PROVIDER_RECHARGE: {
			PROVIDERS_RECHARGE: (id) => {
				return {
					method: 'get',
					url: `admin/recharge_providers/${id}/get_recharge_providers_country`,
				}
			},
			UPDATE_PROVIDER_RECHARGE: (id) => {
				return {
					method: 'put',
					url: `admin/recharge_providers/${id}`,
				}
			}
		},
		ATTACHMENT_FILES: {
			ATTACHMENT_FILES_LIST: (resource, resource_id) => {
				return {
					method: 'get',
					url: `admin/${resource}/${resource_id}/attachments/`
				}
			},
			ATTACHMENT_FILES_CREATE: (resource, resource_id) => {
				return {
					method: 'post',
					url: `admin/${resource}/${resource_id}/attachments/`
				}
			},
			ATTACHMENT_FILES_EDIT: (resource, resource_id, element_id) => {
				return {
					method: 'put',
					url: `admin/${resource}/${resource_id}/attachments/${element_id}`
				}
			},
			ATTACHMENT_FILES_DELETE: (resource, resource_id, element_id) => {
				return {
					method: 'delete',
					url: `admin/${resource}/${resource_id}/attachments/${element_id}`
				}
			},
		},
		FORTRESS: {
			GET_DEPOSIT_INSTRUCTIONS: {
				method: 'post',
				url: 'admin/fortress/get_fiat_deposit_instructions',
			},
			GET_BALANCES_CUSTODIAL_ACCOUNT: {
				method: 'post',
				url: 'admin/fortress/get_balances_custodial_account',
			},
			GET_CUSTODIAL_ACCOUNTS: {
				method: 'get',
				url: 'admin/fortress/get_custodial_accounts',
			},
			CREATE_CUSTODIAL_ACCOUNTS: {
				method: 'post',
				url: 'admin/fortress/create_custodial_account',
			},
		},
		ALERTS: {
			UPDATE_ALERT: (alert_id) => {
				return {
					method: 'put',
					url: `admin/general_alerts/${alert_id}`
				}
			},
		},
		LINK_PAYMENT: {
			GET_PROVIDERS: (id) => {
				return {
					method: 'get',
					url: `admin/countries/${id}/payment_order_providers`,
				}
			},
			UPDATE_PROVIDER_RECHARGE: (idCountry, providerId) => {
				return {
					method: 'put',
					url: `admin/countries/${idCountry}/payment_order_providers/${providerId}`,
				}
			}
		},
		AUTOMATIC_PAYMENT_ROUTES: {
			LIST: (countryId) => {
				return {
					method: 'get',
					url: `admin/countries/${countryId}/automatic_payment_routes`
				}
			},
			CREATE: (countryId) => {
				return {
					method: 'post',
					url: `admin/countries/${countryId}/automatic_payment_routes`
				}
			},
			UPDATE: (countryId, automaticPaymentRouteId) => {
				return {
					method: 'put',
					url: `admin/countries/${countryId}/automatic_payment_routes/${automaticPaymentRouteId}`
				}
			},
			DELETE: (countryId, automaticPaymentRouteId) => {
				return {
					method: 'delete',
					url: `admin/countries/${countryId}/automatic_payment_routes/${automaticPaymentRouteId}`
				}
			}
		},
		AUTOMATIC_PAYMENT_CRYPTO_ROUTES: {
			LIST: (countryId) => {
				return {
					method: 'get',
					url: `admin/countries/${countryId}/automatic_payment_crypto_routes`
				}
			},
			CREATE: (countryId) => {
				return {
					method: 'post',
					url: `admin/countries/${countryId}/automatic_payment_crypto_routes`
				}
			},
			UPDATE: (countryId, automaticPaymentCryptoRouteId) => {
				return {
					method: 'put',
					url: `admin/countries/${countryId}/automatic_payment_crypto_routes/${automaticPaymentCryptoRouteId}`
				}
			},
			DELETE: (countryId, automaticPaymentCryptoRouteId) => {
				return {
					method: 'delete',
					url: `admin/countries/${countryId}/automatic_payment_crypto_routes/${automaticPaymentCryptoRouteId}`
				}
			}
		},
		PIX: {
			GET_PIX_PROVIDERS: {
				method: 'get',
				url: 'admin/pix_keys'
			},
			CREATE_PIX_PROVIDER: {
				method: 'post',
				url: 'admin/pix_keys'
			},
			DELETE_PIX_PROVIDER: (id) => (
				{
					method: 'delete',
					url: `admin/pix_keys/${id}`
				}
			),
			RETURN_WITHDRAWAL: {
				method: 'delete',
				url: 'admin/refund_pix_order'
			},
		},
		CRYPTO_WALLETS: {
			UPDATE: (record_id) => {
				return {
					method: 'put',
					url: `admin/crypto_addresses/${record_id}`
				}
			}
		}
	},
	PUBLIC: {
		GET_OCCUPATIONS: {
			method: 'get',
			url: 'occupations'
		}
	}
};

const ENCRYPT = {
	prod: {
		key: "95mtM#xN1A$qR3w6HxMcCpu@A5GjStUk",
	},
	stage: {
		key: "#Ke@58Z9X!Y1mVXj2V59bCc0z!ZDWC$X",
	},
	qa: {
		key: "#Ke@58Z9X!Y1mVXj2V59bCc0z!ZDWC$X",
	},
	dev: {
		key: "Dg57wzfnmGhyAk*pmW*n7e$*fmf1TKXA",
	},
	local: {
		key: "Dg57wzfnmGhyAk*pmW*n7e$*fmf1TKXA",
	},
};

const RECAPTCHA = {
	prod: "6LezpmsfAAAAAGHJjkr1m83BiWiXTLkLTFwdOSqX",
	stage: '6LemyGsfAAAAAB2JCQKud0Cvfin-q5DQS2x3lTsB',
	qa: '6LemyGsfAAAAAB2JCQKud0Cvfin-q5DQS2x3lTsB',
	dev: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
	local: "6LemyGsfAAAAAB2JCQKud0Cvfin-q5DQS2x3lTsB",
};

export const INDEX = {
	prod: true,
	stage: false,
	qa: false,
	dev: false,
	local: false
};

export const HAS_INDEX = INDEX[ENV];

export const API_URL = ENDPOINTS.API[ENV];
export const TRACK_URL = ENDPOINTS.TRACK[ENV];
export const ENCRYPT_HASH = ENCRYPT[ENV];
export const GOOGLE_RECAPTCHA = RECAPTCHA[ENV];
export const URL_PAGE = ENDPOINTS.PAGE[ENV];
